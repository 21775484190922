import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["box"];

  connect() {
    this.clean_state = true;
    this.showAddButton();
    this.addRowIfEmpty();
  }

  disconnect() {
    this.removeAllAssocRows();
  }

  addRowIfEmpty() {
    const firstAddButton = this.boxTarget.querySelector('.add_fields');
    this.assocRows().length === 0 && firstAddButton.click();
  }

  assocRows() {
    return this.boxTarget.querySelectorAll('.good-row');
  }

  addButton() {
    return this.boxTarget.querySelector('.add_fields');
  }

  showAddButton() {
    const addButton = this.addButton();
    addButton && addButton.classList.remove('d-none');
  }

  removeAllAssocRows() {
    this.assocRows().forEach(row => row.remove());
  }

  isFormDirty() {
    return this.clean_state === false;
  }

  makeFormDirty() {
    if (this.isFormDirty()) this.removeAllAssocRows();
    this.clean_state = false;
  }

  removeRow(event) {
    event.currentTarget.closest('.good-row').nextElementSibling.value = "true";
    event.currentTarget.closest('.good-row').remove();
    return event.preventDefault();
  }
}
