import $ from "jquery";
import select2 from 'select2';
import debounce from 'debounce';

import { Controller } from "@hotwired/stimulus";

export default class TableController extends Controller {
  static targets = [
    'beforeTable',
    'table',
    'columnHeader',
    'tableBody',
    'tableFooter',
    'pagination',
    'perPageDropdown',
    'queryInput',
    'filtersArea',
    'dateRangeFilter',
    'textFilter',
    'selectFilter',
    'multipleSelectFilter',
    'connectedSelectFilter',
    'populateSelectFilter',
    'selectGroupingFilter',
  ]

  static values = {
    url: String,
    baseUrl: String,
    columns: Array,
    actions: Array,
    filters: Array,
    entriesCount: Number,
    perPage: Number,
    placeholder: String,
    queryable: Boolean
  }

  initialize() {
    select2();
    this.urlValueChanged = debounce(this.urlValueChanged.bind(this), 200);
  }

  async connect() {
    this.#setUrlDefaults();
    this.#createBeforeTableTarget();
    this.#createFiltersArea();
    this.#createQueryInput();
    this.#createPerPageDropdown();
    this.#createTableHeader();
    this.#createTableBody();
    this.#createTableFooter();
    this.#setPagination();

    const select2Elements = [
      ...$(this.selectFilterTargets),
      ...$(this.multipleSelectFilterTargets),
      ...$(this.connectedSelectFilterTargets),
      ...$(this.selectGroupingFilterTargets),
    ]

    select2Elements.forEach(elem => {
      $(elem).on('select2:select', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      })

      $(elem).on('select2:unselect', function () {
        let event = new Event('change', { bubbles: true })
        this.dispatchEvent(event);
      })
    });

    await this.#populateExistingFilters();
  }

  // ==============================
  // State management
  // ==============================
  get currentUrl() {
    const url = this.urlValue.startsWith('http') ? this.urlValue : `${window.location.origin}${this.urlValue}`;
    return new URL(url);
  }

  get currentPage() {
    const page = this.currentUrl.searchParams.get('page');
    return Number(page) === 0 ? "1" : page;
  }

  get currentPerPage() {
    return this.currentUrl.searchParams.get('per_page') || this.perPageValue;
  }

  get currentTotalPages() {
    return Math.ceil(this.entriesCountValue / this.currentPerPage);
  }

  // ==============================
  // Event handlers
  // ==============================
  updateSortParams(event) {
    event.preventDefault();

    const url = this.currentUrl;
    const columnHeader = event.currentTarget.closest('th');
    const sortDirection = columnHeader.dataset.sortDirection === 'asc' ? 'desc' : 'asc';

    columnHeader.dataset.sortDirection = sortDirection;
    url.searchParams.set('sort', columnHeader.dataset.sortColumn);
    url.searchParams.set('sort_direction', sortDirection);

    this.urlValue = url.toString();
  }

  updateFilterParams(event) {
    event.preventDefault();

    const url = this.currentUrl;
    const trigger = event.currentTarget;
    const column_name = trigger.dataset.columnName

    const targetNames = trigger.dataset.tableTarget.split(' ');

    if (targetNames.includes('textFilter')) {
      url.searchParams.set(column_name, trigger.value);
    } else if (targetNames.includes('dateRangeFilter')) {
      const dateFields = this.dateRangeFilterTargets.filter((target) => target.dataset.columnName === column_name);
      const start_value = dateFields.find((target) => target.dataset.dateKind === 'from').value;
      const end_value = dateFields.find((target) => target.dataset.dateKind === 'to').value;

      start_value ? url.searchParams.set(`${column_name}_from`, start_value) : url.searchParams.delete(`${column_name}_from`);
      end_value ? url.searchParams.set(`${column_name}_to`, end_value) : url.searchParams.delete(`${column_name}_to`);
    } else if (targetNames.includes('multipleSelectFilter')) {
      const selected_values = $(trigger).select2('data').map((item) => item.id);
      url.searchParams.set(column_name, selected_values.join(','));
    }

    this.urlValue = url.toString();
  }

  updateGroupingParams(event) {
    event.preventDefault();

    const url = this.currentUrl;
    const trigger = event.currentTarget;

    const targetNames = trigger.dataset.tableTarget.split(' ');
    url.searchParams.set('grouping', trigger.value);

    this.urlValue = url.toString();
  }

  updatePageParams(event) {
    event.preventDefault();

    const url = this.currentUrl;
    url.searchParams.set('page', event.currentTarget.dataset.page);

    this.urlValue = url.toString();
  }

  updatePerPageParams(event) {
    event.preventDefault();

    const url = this.currentUrl;

    this.perPageValue = event.currentTarget.dataset.perPage;
    url.searchParams.set('per_page', event.currentTarget.dataset.perPage);

    this.urlValue = url.toString();
  }

  updateQueryParams(event) {
    event.preventDefault();

    const url = this.currentUrl;
    url.searchParams.set('query', event.currentTarget.value);

    this.urlValue = url.toString();
  }

  statusFormatter(row, column) {
    const statusText = row.status_text;
    const statusColor = row.status_color;
    return `<span class="badge badge-${statusColor} py-1 px-2 rounded-pill">${statusText}</span>`;
  }

  async clearFilters(event) {
    event.preventDefault();

    let url = this.currentUrl;
    const page = url.searchParams.get('page');
    const per_page = url.searchParams.get('per_page');

    url = new URL(this.currentUrl.toString().split('?')[0]);
    url.searchParams.set('page', page);
    url.searchParams.set('per_page', per_page);

    this.urlValue = url.toString();

    await this.#populateExistingFilters();
  }

  async populateConnectedSelect(event) {
    event.preventDefault();

    const trigger = event.currentTarget;
    const populateUrl = trigger.dataset.populateUrl
    const populateParam = trigger.dataset.populateParam;
    const populateParamValue = $(trigger).select2('data').map((item) => item.id).join(',');

    const populateFetchUrl = `${populateUrl}?${populateParam}=${populateParamValue}`;
    const populateSelect = this.populateSelectFilterTargets.find((target) => target.dataset.columnName === trigger.dataset.populateTargetColumn);

    if (populateParamValue.trim() !== '') {
      fetch(`${window.location.origin}/${populateFetchUrl}`, { headers: { accept: 'application/json' } })
        .then(response => response.json())
        .then(response_json => {
          if (response_json.result === 'error') {
            return;
          }

          const items = response_json[`${trigger.dataset.populateResponseField}`]

          $(populateSelect).empty().trigger('change');
          items.forEach((item) => {
            const option = new Option(item.name, item.id, false, false);
            $(populateSelect).append(option).trigger('change');
          })

          const currentUrl = this.currentUrl;
          currentUrl.searchParams.set(populateSelect.dataset.columnName, items.map((item) => item.id).join(','));

          this.urlValue = currentUrl.toString();
        })
    } else {
      $(populateSelect).empty().trigger('change');
      const currentUrl = this.currentUrl;
      currentUrl.searchParams.delete(populateSelect.dataset.columnName);

      this.urlValue = currentUrl.toString();
    }
  }

  // ==============================
  // Stimulus triggered handlers
  // ==============================
  async urlValueChanged() {
    this.tableBodyTarget.innerHTML = this.#loadingRow();
    const response = await fetch(this.urlValue, { headers: { accept: 'application/json' } });
    const response_json = await response.json();

    this.tableBodyTarget.innerHTML = '';

    this.entriesCountValue = response_json.total_count;

    !this.entriesCountValue
      ? this.tableBodyTarget.appendChild(this.#emptyRow())
      : this.#createRows(response_json);

    this.#setPagination();

    // window.history.pushState("Search results", "Search results", this.urlValue);
  }

  // ==============================
  // Private methods
  // ==============================

  #setUrlDefaults() {
    const url = this.currentUrl
    url.searchParams.set('per_page', this.perPageValue);
    url.searchParams.set('page', '1');
    this.urlValue = url.toString();
  }

  #createBeforeTableTarget() {
    if (this.hasBeforeTableTarget) {
      return;
    }

    const wrapper = document.createElement('div');
    wrapper.setAttribute('data-table-target', 'beforeTable')
    wrapper.classList.add('d-flex', 'justify-content-between', 'align-items-center', 'mb-2');

    this.tableTarget.before(wrapper);
  }

  #createQueryInput() {
    if (!this.queryableValue || this.hasQueryInputTarget) {
      return;
    }

    const queryInput = document.createElement('input');
    queryInput.classList.add('form-control', 'string', 'rounded-lg', 'bg-white', 'shadow-sm', 'p-3', 'mt-2');
    queryInput.setAttribute('type', 'text');
    queryInput.setAttribute('data-action', 'input->table#updateQueryParams');
    queryInput.setAttribute('data-table-target', 'queryInput');

    queryInput.setAttribute('placeholder', this.placeholderValue || 'Search...');

    this.beforeTableTarget.appendChild(queryInput);
  }

  #createPerPageDropdown() {
    if (this.hasPerPageDropdownTarget || Number(this.entriesCountValue) <= 10) {
      return;
    }

    const perPageDropdown = document.createElement('div');
    perPageDropdown.classList.add('dropdown');
    perPageDropdown.setAttribute('data-table-target', 'perPageDropdown');

    const button = document.createElement('button');
    button.classList.add('btn', 'btn-sm', 'dropdown-toggle');
    button.style = "box-shadow:none !important; outline: none !important;";
    button.setAttribute('type', 'button');
    button.setAttribute('data-toggle', 'dropdown');
    button.setAttribute('aria-haspopup', 'true');
    button.setAttribute('aria-expanded', 'false');
    button.innerHTML = `${this.currentPerPage} per page`;

    perPageDropdown.appendChild(button);

    const dropdownMenu = document.createElement('div');
    dropdownMenu.classList.add('dropdown-menu');

    [10, 25, 50, 100].forEach((perPage) => {
      const dropdownItem = document.createElement('a');
      dropdownItem.classList.add('dropdown-item');
      dropdownItem.setAttribute('href', '#');
      dropdownItem.setAttribute('data-action', 'click->table#updatePerPageParams');
      dropdownItem.setAttribute('data-per-page', perPage.toString());
      dropdownItem.textContent = `${perPage} per page`;

      dropdownMenu.appendChild(dropdownItem);
    })

    perPageDropdown.appendChild(dropdownMenu);

    this.beforeTableTarget.appendChild(perPageDropdown);
  }

  #createFiltersArea() {
    if (this.filtersValue.length === 0 || this.hasFiltersAreaTarget) {
      return;
    }

    const detailsElement = document.createElement('details');
    detailsElement.setAttribute('open', 'open');
    detailsElement.classList.add('p-0', 'mb-4');
    const summaryElement = document.createElement('summary');
    summaryElement.classList.add('mb-4');
    summaryElement.textContent = 'Filters';
    detailsElement.appendChild(summaryElement);

    const filtersArea = document.createElement('div');
    filtersArea.classList.add('form-row', 'my-2', 'mx-0');
    filtersArea.setAttribute('data-table-target', 'filtersArea');

    this.filtersValue.forEach((filter) => {
      filtersArea.innerHTML += filter;
    })

    detailsElement.appendChild(filtersArea);

    const row = document.createElement('div');
    row.classList.add('form-row', 'mt-2', 'mx-0', 'justify-content-end');
    const clearFiltersButton = document.createElement('a');
    clearFiltersButton.classList.add('mr-2', 'text-primary');
    clearFiltersButton.setAttribute('role', 'button');
    clearFiltersButton.setAttribute('data-action', 'click->table#clearFilters');
    clearFiltersButton.textContent = 'Clear filters';

    row.appendChild(clearFiltersButton);
    detailsElement.appendChild(row);

    this.beforeTableTarget.before(detailsElement);
  }

  #createTableHeader() {
    if (this.hasColumnHeaderTarget) {
      return
    }

    const thead = document.createElement('thead');
    const tr = document.createElement('tr');
    tr.classList.add('p-2');

    this.columnsValue.forEach((column) => {
      const columnHeader = document.createElement('th');
      columnHeader.classList.add('text-center');
      if (column.sortable) {
        columnHeader.dataset.column = column.column_name;
        columnHeader.dataset.sortColumn = column.sort_column;
        columnHeader.dataset.sortDirection = column.sort_direction;
        columnHeader.innerHTML = `<a href="#">${column.label}</a>`;
        columnHeader.setAttribute('scope', 'col');
        columnHeader.setAttribute('data-table-target', 'columnHeader');
        columnHeader.setAttribute('data-action', 'click->table#updateSortParams');
      } else {
        columnHeader.innerHTML = column.label;
        columnHeader.setAttribute('scope', 'col');
        columnHeader.setAttribute('data-table-target', 'columnHeader');
      }
      tr.appendChild(columnHeader);
    })

    thead.appendChild(tr);

    this.tableTarget.appendChild(thead);
  }

  #createTableBody() {
    if (this.hasTableBodyTarget) {
      return;
    }

    const tbody = document.createElement('tbody');
    tbody.setAttribute('data-table-target', 'tableBody');

    this.tableTarget.appendChild(tbody);
  }

  #createRows(response_json) {
    response_json.entries.forEach((row) => {
      const currentRow = document.createElement('tr');
      currentRow.classList.add('p-4', 'border-b');
  
      this.columnsValue.forEach((column) => {
        const cell = document.createElement('td');
        cell.classList.add('text-center');
        let cellContent;
  
        if (column.formatter && typeof this[column.formatter] === 'function') {
          cellContent = this[column.formatter](row, column);
        } else {
          cellContent = row[column.column_name];
        }
  
        if (column.column_name === 'bl_number') {
          if (row.rejected) {
            cell.innerHTML = `<span style="color:#FB3640">${row[column.column_name]}</span>`;
          } else {
            cell.textContent = row[column.column_name];
          }
        } else if (cellContent instanceof HTMLElement) {
          cell.appendChild(cellContent);
        } else {
          cell.innerHTML = cellContent;
        }
  
        currentRow.appendChild(cell);
      });
  
      if (this.actionsValue.length > 0) {
        const actionsCell = this.#createActionsCell(row);
        currentRow.appendChild(actionsCell);
      }
  
      this.tableBodyTarget.appendChild(currentRow);
    });
  }

  #createActionsCell(row) {
    const actionsCell = document.createElement('td');
    actionsCell.style = "width: 2% !important;";
    actionsCell.classList.add('text-left');
  
    const dropdown = document.createElement('div');
    dropdown.classList.add('dropdown');
  
    const button = document.createElement('button');
    button.classList.add('text-muted', 'border-0', 'bg-transparent');
    button.setAttribute('type', 'button');
    button.setAttribute('data-toggle', 'dropdown');
    button.setAttribute('aria-expanded', 'false');
  
    const icon = document.createElement('i');
    icon.classList.add('fa-solid', 'fa-ellipsis-vertical');
    button.appendChild(icon);
    dropdown.appendChild(button);
  
    const dropdownMenu = document.createElement('div');
    dropdownMenu.classList.add('dropdown-menu');
  
    this.actionsValue.forEach((action) => {
      switch (action) {
        case 'show':
          dropdownMenu.appendChild(this.#showButtonHTML(row.id));
          break;
        case 'edit':
          if (row.editable) {
            dropdownMenu.appendChild(this.#editButtonHTML(row.id));
          }
          break;
        case 'download':
          dropdownMenu.appendChild(this.#draftButtonHTML(row.id, 'Download'));
          break;
        case 'attachment':
          if (row.attachment) {
            dropdownMenu.appendChild(this.#fileDownloadButtonHTML(row.attachment));
          }
          break;
      }
    });
  
    dropdown.appendChild(dropdownMenu);
    actionsCell.appendChild(dropdown);
  
    return actionsCell;
  }

  #emptyRow() {
    const emptyRow = document.createElement('tr');
    const emptyCell = document.createElement('td');

    emptyCell.setAttribute('colspan', this.columnsValue.length + 1);
    emptyCell.classList.add('text-center');
    emptyCell.textContent = 'No results found.';

    emptyRow.appendChild(emptyCell);

    return emptyRow;
  }

  #loadingRow() {
    const loadingRow = document.createElement('tr');
    const loadingCell = document.createElement('td');

    loadingCell.setAttribute('colspan', this.columnsValue.length + 1);
    loadingCell.classList.add('text-center');
    loadingCell.textContent = 'Loading...';

    loadingRow.appendChild(loadingCell);

    return loadingRow;
  }

  #showButtonHTML(id) {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', `${this.baseUrlValue.split('?')[0]}/${id}`);
    action.textContent = 'Show';

    return action
  }

  #editButtonHTML(id) {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', `${this.baseUrlValue.split('?')[0]}/${id}/edit`);
    action.textContent = 'Edit';

    return action
  }

  #draftButtonHTML(id, label) {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', `${this.baseUrlValue.split('?')[0]}/${id}.pdf`);
    action.setAttribute('download', 'download');
    action.textContent = label || 'Draft';

    return action
  }

  #fileDownloadButtonHTML(attachmentPath, label = "Attachment") {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', attachmentPath);
    action.setAttribute('download', 'download');
    action.textContent = label;

    return action
  }

  #changelogButtonHTML(id, label = "Changelog") {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', `/entries/${id}/changelog`);
    action.textContent = label;

    return action
  }

  #linkButtonHTML(path, label = "Link") {
    const action = document.createElement('a');

    action.classList.add('dropdown-item');
    action.setAttribute('href', path);
    action.textContent = label;

    return action
  }

  #createTableFooter() {
    if (this.hasTableFooterTarget) {
      return;
    }

    const tfoot = document.createElement('tfoot');
    tfoot.setAttribute('data-table-target', 'tableFooter')

    const tr = document.createElement('tr');
    tr.classList.add('p-2');

    const td = document.createElement('td');
    td.setAttribute('colspan', this.columnsValue.length + 1);
    td.classList.add('text-center');
    td.innerHTML = `
      <nav aria-label="Page navigation">
        <ul class="pagination justify-content-center border-0" data-table-target="pagination">
        </ul>
      </nav>`;

    tr.appendChild(td);

    tfoot.appendChild(tr);

    this.tableTarget.appendChild(tfoot);
  }

  #setPagination() {
    this.paginationTarget.innerHTML = '';

    // Update per page dropdown
    if (this.hasPerPageDropdownTarget) {
      this.perPageDropdownTarget.querySelector('button').innerHTML = `${this.currentPerPage} per page`;
    }


    if (this.currentPage > this.currentTotalPages) {
      const updateUrl = this.currentUrl;
      updateUrl.searchParams.set('page', this.currentTotalPages.toString());
      this.urlValue = updateUrl.toString();
    }

    if (Number(this.currentTotalPages) <= 1) {
      return;
    }

    this.paginationTarget.appendChild(this.#firstButton());
    this.paginationTarget.appendChild(this.#previousButton());

    const start = Math.max(1, this.currentPage - 3);
    const end = this.currentTotalPages;

    if (start > 1) {
      const paginationItem = document.createElement('li');
      paginationItem.classList.add('page-item', 'disabled');
      paginationItem.innerHTML = `<span class="page-link">...</span>`;
      this.paginationTarget.appendChild(paginationItem);
    }

    for (let page = start; page <= end; page++) {
      const paginationItem = document.createElement('li');
      paginationItem.classList.add('page-item');

      const pageUrl = this.currentUrl;
      pageUrl.searchParams.set('page', page.toString());

      if (Number(page) === Number(this.currentPage)) {
        paginationItem.classList.add('active');
        paginationItem.innerHTML = `<span class="page-link">${page}</span>`;
      } else {
        paginationItem.innerHTML = `<a class="page-link" href="" data-action="click->table#updatePageParams" data-page="${page}">${page}</a>`;
      }
      if (page > start + 10 && page !== end) {
        continue;
      }
      if (page === end && end - Number(this.currentPage) > 8) {
        const threeDotItem = document.createElement('li');
        threeDotItem.classList.add('page-item', 'disabled');
        threeDotItem.innerHTML = `<span class="page-link">...</span>`;
        this.paginationTarget.appendChild(threeDotItem);
      }
      this.paginationTarget.appendChild(paginationItem);
    }

    this.paginationTarget.appendChild(this.#nextButton());
    this.paginationTarget.appendChild(this.#lastButton());
  }

  #previousButton() {
    const previousPage = Number(this.currentPage) - 1;
    const previousPageUrl = this.currentUrl;
    previousPageUrl.searchParams.set('page', previousPage.toString());

    const previousPageItem = document.createElement('li');
    previousPageItem.classList.add('page-item');
    if (previousPage < 1) {
      previousPageItem.classList.add('disabled');
      previousPageItem.innerHTML = `<span class="page-link">Previous</span>`;
    } else {
      previousPageItem.innerHTML = `<a class="page-link" href="" data-action="click->table#updatePageParams" data-page="${previousPage}">Previous</a>`;
    }

    return previousPageItem;
  }

  #nextButton() {
    const nextPage = Number(this.currentPage) + 1;
    const nextPageUrl = this.currentUrl;
    nextPageUrl.searchParams.set('page', nextPage.toString());

    const nextPageItem = document.createElement('li');
    nextPageItem.classList.add('page-item');
    if (nextPage > this.currentTotalPages) {
      nextPageItem.classList.add('disabled');
      nextPageItem.innerHTML = `<span class="page-link">Next</span>`;
    } else {
      nextPageItem.innerHTML = `<a class="page-link" href="" data-action="click->table#updatePageParams" data-page="${nextPage}">Next</a>`;
    }

    return nextPageItem;
  }

  #firstButton() {
    const firstPage = 1;
    const firstPageUrl = this.currentUrl;
    firstPageUrl.searchParams.set('page', firstPage.toString());

    const firstPageItem = document.createElement('li');
    if (Number(this.currentPage) === firstPage) {
      firstPageItem.classList.add('page-item', 'active');
      firstPageItem.innerHTML = `<span class="page-link">First</span>`;
    } else {
      firstPageItem.innerHTML = `<a class="page-link" href="" data-action="click->table#updatePageParams" data-page="${firstPage}">First</a>`;
    }

    return firstPageItem;
  }

  #lastButton() {
    const lastPage = this.currentTotalPages;
    const lastPageUrl = this.currentUrl;
    lastPageUrl.searchParams.set('page', lastPage.toString());

    const lastPageItem = document.createElement('li');
    if (Number(this.currentPage) === lastPage) {
      lastPageItem.classList.add('page-item', 'active');
      lastPageItem.innerHTML = `<span class="page-link">Last</span>`;
    } else {
      lastPageItem.innerHTML = `<a class="page-link" href="" data-action="click->table#updatePageParams" data-page="${lastPage}">Last</a>`;
    }

    return lastPageItem;
  }

  async #populateExistingFilters() {
    // Populate existing text filters
    const textFilters = this.textFilterTargets;
    textFilters.forEach((filter) => {
      const url = this.currentUrl;
      const column_name = filter.dataset.columnName
      const value = url.searchParams.get(column_name);

      if (value) {
        filter.value = value;
      } else {
        filter.value = '';
      }
    })

    // Populate existing date range filters
    const dateRangeFilters = this.dateRangeFilterTargets;
    dateRangeFilters.forEach((filter) => {
      const url = this.currentUrl;
      const column_name = filter.dataset.columnName

      if (filter.dataset.dateKind === 'from') {
        const value = url.searchParams.get(`${column_name}_from`);
        if (value) {
          filter.value = value;
        } else {
          filter.value = '';
        }
      }

      if (filter.dataset.dateKind === 'to') {
        const value = url.searchParams.get(`${column_name}_to`);
        if (value) {
          filter.value = value;
        } else {
          filter.value = '';
        }
      }
    })

    // Populate existing multiple select filters
    const multipleSelectFilters = this.multipleSelectFilterTargets;
    multipleSelectFilters.forEach((filter) => {
      const url = this.currentUrl;
      const column_name = filter.dataset.columnName
      const value = url.searchParams.get(column_name);

      if (value) {
        const values = value.split(',');
        $(filter).val(values).trigger('change');
      } else {
        $(filter).val(null).trigger('change');
      }
    })

    // Populate existing grouping select filters
    const groupingSelectFilters = this.selectGroupingFilterTargets;
    groupingSelectFilters.forEach((filter) => {
      const url = this.currentUrl;
      const value = url.searchParams.get('grouping');

      if (value) {
        $(filter).val(value).trigger('change');
      } else {
        $(filter).val(null).trigger('change');
      }
    })

    // Populate existing connected select filters
    const connectedSelectFilters = this.connectedSelectFilterTargets;
    for (const filter of connectedSelectFilters) {
      $(filter).select2({ width: '100%' });

      if ($(filter).select2('data').length > 0) {
        await this.populateConnectedSelect({
          currentTarget: filter, preventDefault: () => {
          }
        });
      }
    }

    // Populate query input
    if (this.hasQueryInputTarget) {
      const url = this.currentUrl;
      const value = url.searchParams.get('query');

      if (value) {
        this.queryInputTarget.value = value;
      } else {
        this.queryInputTarget.value = '';
      }
    }
  }
}
