import FormUtils from '../helpers/form-utils';
import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

const getErrorNode = text => {
  const errorNode = document.createElement('div');
  errorNode.classList.add('invalid-feedback');
  errorNode.id = 'error-node';
  errorNode.innerText = text;
  return errorNode;
};

export default class FormController extends Controller {
  isSubmitted = false;
  validationRules = {};
  values = {};

  constructor(context, validationRules, optionalRuleIds = []) {
    super(context);

    if (optionalRuleIds.length) {
      optionalRuleIds.forEach(ruleId => {
        if (!this.element.querySelector(`#${ruleId}`)) {
          delete validationRules[ruleId];
        }
      });
    }

    this.validationRules = validationRules;

    try {
      this.setPhoneValidationEvents();
    } catch { }

    try {
      Object.entries(this.validationRules).forEach(
        ([id, { isValid, errorMessage }]) => {
          const input = document.getElementById(id);

          if (input) {
            if (input.type === 'file') {
              input.addEventListener(FormUtils.fileCountChangeEvent.type, e => {
                this.values[id] = e.target.dataset.fileCount;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              });
            } else if (input.type === 'checkbox') {
              input.onchange = e => {
                this.values[id] = e.target.checked;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              };
            } else {
              input.oninput = e => {
                this.values[id] = e.target.value;
                if (this.isSubmitted) {
                  this.validateInput(id, isValid, errorMessage);
                }
              };
            }
          } else {
            console.error(`Can't find input with id: ${id}`);
          }
        },
      );
    } catch {
      console.error("Can't initialize form controller");
    }
  }

  validateForm() {
    const validateStatuses = Object.entries(this.validationRules).map(
      ([id, { isValid, errorMessage }]) => {
        return this.validateInput(id, isValid, errorMessage);
      },
    );
    this.isSubmitted = true;
    this.validateGoods();
    this.validateShipmentTypes();

    return validateStatuses.every(isValid => isValid) && this.validateGoods() && this.validateShipmentTypes();
    // return validateStatuses.every(isValid => isValid) && this.validateGoods();
  }

  validateInput(id, isValid, errorMessage) {
    const input = document.getElementById(id);
    const submitButton = this.#getForm().querySelector('input[type="submit"]');

    if (!isValid(input, this.values)) {
      if (!input.classList.contains('is-invalid')) {
        const errorNode = getErrorNode(errorMessage);
        input.parentNode.appendChild(errorNode);
        input.classList.add('is-invalid');
        document
          .getElementById(id)
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return false;
    }

    const errorNode = input.parentNode.querySelector('#error-node');
    errorNode && errorNode.remove();
    input.classList.remove('is-invalid');
    submitButton.removeAttribute('data-disable-with');
    submitButton.disabled = false;
    return true;
  }

  validateShipmentTypes() {
    let invalidFound = false;

    const totalGrossWeightInput = $('.entry_total_gross_weight input');
    const fillAllWarning = $('.fill-all-warning');

    if (totalGrossWeightInput && totalGrossWeightInput.val() === '0.00') {
      fillAllWarning.remove();
      totalGrossWeightInput.addClass('is-invalid');
      totalGrossWeightInput.parent().append(`<div class="fill-all-warning">Please enter a valid number</div>`);
      invalidFound = true;
    } else {
      totalGrossWeightInput.removeClass('is-invalid');
      totalGrossWeightInput.parent().find('.fill-all-warning').remove();
    }

    $(".assoc-row").each((_, row) => {
      const selectControl = $(row).find('.form-control.select');
      const stringControls = $(row).find('.form-control.string');
      const containerNumbers = $('[data-association="containers"] div .entry_containers_container_number input');
      const containerNumberErrorMessage = 'Container numbers must be unique.';
      const requiredErrorMessage = 'Please fill in all fields.';
      const fillAllWarning = $(row).find('.fill-all-warning');
      const formGroup = $(row).find('.form-group');

      // Reset the error states
      if (fillAllWarning.length) {
        fillAllWarning.remove();
      }
      selectControl.removeClass('is-invalid');

      let stringControlEmpty = false;
      let sameContainerNumber = false;

      stringControls.each((_, stringControl) => {
        if ($(stringControl).val() === '') {
          stringControlEmpty = true;
        }
      });

      containerNumbers.each((_, containerNumber) => {
        let values = [];
        containerNumbers.each((_, containerNumber) => {
          values.push(containerNumber.value);
        });

        if (values.filter(value => value === containerNumber.value).length > 1) {
          sameContainerNumber = true;
        }
      });

      if (selectControl.find(":selected").text().indexOf('Select') !== -1 || stringControlEmpty) {
        formGroup.css('margin-bottom', '0');
        $(row).append(`<div class="fill-all-warning">${requiredErrorMessage}</div>`);
        invalidFound = true;
      } else if (sameContainerNumber) {
        formGroup.css('margin-bottom', '0');
        $(row).append(`<div class="fill-all-warning">${containerNumberErrorMessage}</div>`);
        invalidFound = true;
      } else {
        formGroup.css('margin-bottom', '1rem');
        invalidFound = false;
      }
    });

    return !invalidFound;
  }

  validateGoods() {
    let invalidFound = false;

    $(".good-row").each((_, row) => {
      const selectControl = $(row).find('.form-control.select');
      const numericControl = $(row).find('.form-control.numeric');
      const requiredErrorMessage = 'Please fill in all fields.';
      const validErrorMessage = 'Please enter a valid number for quantity.';
      const fillAllWarning = $(row).find('.fill-all-warning');
      const formGroup = $(row).find('.form-group');

      // Reset the error states
      if (fillAllWarning.length) {
        fillAllWarning.remove();
      }
      selectControl.removeClass('is-invalid');
      numericControl.removeClass('is-invalid');
      $(row).find('.invalid-feedback').remove();

      if (selectControl.find(":selected").text().indexOf('Select') !== -1 || numericControl.val() === '') {
        formGroup.css('margin-bottom', '0');
        numericControl.css('margin-bottom', '0');
        $(row).append(`<div class="fill-all-warning">${requiredErrorMessage}</div>`);
        invalidFound = true;
      } else if (numericControl.val() <= 0) {
        formGroup.css('margin-bottom', '0');
        numericControl.css('margin-bottom', '0');
        $(row).append(`<div class="fill-all-warning">${validErrorMessage}</div>`);
        invalidFound = true;
      } else {
        selectControl.removeClass('is-invalid');
        $(".select2-selection").removeClass('is-invalid');
        formGroup.css('margin-bottom', '1rem');
      }

    });

    return !invalidFound;
  }


  submit(e) {
    e.preventDefault();

    const submitButton = this.#getForm().querySelector('input[type="submit"]');

    let submitButtonText;
    if (submitButton) {
      submitButtonText = `${submitButton.value}`;

      submitButton.value = 'Please wait...';
      submitButton.disabled = true;
    }

    const isFormValid = this.validateForm();
    if (isFormValid) {
      this.#getForm().submit();
      // Wait 1 second before re-enabling submit button
      setTimeout(() => {
        if (submitButton) {
          submitButton.value = submitButtonText;
          submitButton.disabled = false;
        }
      }, 1000);
    } else {
      alert('Form is invalid. Please check the form for errors.');
      if (submitButton) {
        submitButton.value = submitButtonText;
        submitButton.disabled = false;
      }
    }
  }

  setPhoneValidationEvents() {
    const phoneInputs = document.querySelectorAll(
      '[data-validate-phone="true"]',
    );

    if (phoneInputs) {
      phoneInputs.forEach(phoneInput => {
        phoneInput.addEventListener('blur', this.formatPhone);
      });
    }
  }

  formatPhone(e) {
    const phoneInput = e.target;

    const { isValid, phoneNumber } = FormUtils.checkPhoneNumber(
      phoneInput.value,
    );

    if (isValid) {
      phoneInput.value = phoneNumber;
    }
  }

  #getForm() {
    return this.element.querySelector('form');
  }
}
