import { Controller } from "@hotwired/stimulus"
import select2 from 'select2'
import $ from 'jquery';

export default class extends Controller {
  static targets = ['select']

  initialize() {
    select2();
  }

  connect() {
    this.initializeSelect2();
  }

  disconnect() {
    $(this.selectTarget).select2("destroy");
  }

  initializeSelect2() {
    $('.select2-custom').each((_, select) => {
      if (
        select.tagName === 'SELECT' &&
        select.id.startsWith('entry_goods_attributes') &&
        select.id.endsWith('_hscode_id')
      ) {
        $(select).select2({ width: '100%' });
      }
    });

    setTimeout(() => {
      this.ajaxifiedHscodeSelect();
    }, 1000);
  }

  ajaxifiedHscodeSelect() {
    $(this.selectTarget).select2({
      minimumInputLength: 3,
      ajax: {
        dataType: 'json',
        delay: 250,
        url: '/hscodes',
        data: function (params) {
          return {
            query: params.term
          }
        },
        processResults: function (data) {
          return {
            results: data.map(function (item) {
              return { id: item.id, text: item.hs_code + ' - ' + item.description }
            })
          }
        }
      }
    });
  }

  duplicateRow(event) {
    event.preventDefault();
    const row = event.currentTarget.closest('.good-row');

    $(row).find('select.select2-hidden-accessible').each(function () {
      $(this).select2('destroy');
    });

    const clone = row.cloneNode(true);

    const inputs = clone.querySelectorAll('input, select');

    inputs.forEach(input => {
      const name = input.getAttribute('name');
      const id = input.getAttribute('id');

      if (name) {
        const newName = name.replace(/(\d+)/, (match) => parseInt(match) + 1);
        input.setAttribute('name', newName);
      }

      if (id) {
        const newId = id.replace(/(\d+)/, (match) => parseInt(match) + 1);
        input.setAttribute('id', newId);
      }

      if (input.tagName === 'INPUT') {
        input.value = row.querySelector(`[name="${name}"]`).value;
      } else if (input.tagName === 'SELECT') {
        const originalSelect = row.querySelector(`[name="${name}"]`);
        input.value = originalSelect.value;
        if ($(input).hasClass('select2-hidden-accessible')) {
          $(input).select2();
        }
      }
    });

    row.parentNode.insertBefore(clone, row.nextSibling);

    setTimeout(() => {
      $(clone).find('.select2-custom').each((_, select) => {
        $(select).select2({ width: '100%' });
      });

      this.ajaxifiedHscodeSelect();
    }, 1000);
  }
}
