import {Controller} from "@hotwired/stimulus";

export default class ConfirmationController extends Controller {
  static values = {
    message: String,
  }

  confirm(event) {
    const confirmed = confirm(this.messageValue);
    const button = event.target;

    if (!confirmed) {
      event.preventDefault();
    } else {
      const form = button.closest('form');

      if (form) {
        form.submit();
      }

      button.disabled = true;
    }
  }
}