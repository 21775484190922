import flatpickr from "flatpickr"
import { Controller } from "@hotwired/stimulus"

export default class DatepickrController extends Controller {
  static targets = ["etd", "eta", "picker", "startDate", "endDate"]
  static values = { isFinancialClearing: Boolean, default: false }

  connect() {
    this.pickerTargets.forEach(element => this.makeFlatPicker(element))

    if (this.etaTargets.length > 0) {
      this.etaTargets.forEach(element => {
        if (element.value !== '')
          this.adjustDates(element)
      })
    }

    if (this.startDateTargets.length > 0) {
      this.startDateTargets.forEach(element => {
        if (element.value !== '')
          this.adjustEndDate(element)
      })
    }
  }

  makeFlatPicker(element) {
    // Check if there's an existing value and parse it
    const existingValue = element.value;
    let parsedDate = null;

    const minDateValue = this.element.dataset.datepickrMinDate;
    const maxDateValue = this.element.dataset.datepickrMaxDate;

    if (existingValue) {
      parsedDate = this.parseDate(existingValue);
    }

    flatpickr(element, {
      dateFormat: "d-m-Y",
      defaultDate: parsedDate
    });

    if (this.isFinancialClearingValue) {
      flatpickr(element, {
        dateFormat: "d-m-Y",
        defaultDate: parsedDate,
        minDate: this.parseDate(minDateValue) || this.addMonths(new Date(), -6),
        maxDate: maxDateValue || "today"
      });
    }
  }

  adjustDates(event) {
    const etdVal = new Date(this.etdTarget.value.split('-').reverse())
    etdVal.setDate(etdVal.getDate() + 1)
    let defaultVal;
    if (this.etaTarget.value === '')
      defaultVal = etdVal
    else {
      const etaVal = new Date(this.etaTarget.value.split('-').reverse())
      if (etaVal <= etdVal)
        defaultVal = etdVal
    }

    this.etaTarget.flatpickr({
      defaultDate: defaultVal,
      dateFormat: "d-m-Y",
      minDate: etdVal
    })
  }

  adjustEndDate(event) {
    const startDate = new Date(this.startDateTarget.value.split('-').reverse())
    startDate.setDate(startDate.getDate())
    const maxDateValue = this.element.dataset.datepickrMaxDate;
    let defaultVal;
    if (this.startDateTarget.value === '')
      defaultVal = startDate
    else {
      const endDateVal = maxDateValue || new Date(this.endDateTarget.value.split('-').reverse())
      if (endDateVal <= startDate)
        defaultVal = startDate
    }

    this.endDateTarget.flatpickr({
      defaultDate: this.isFinancialClearingValue ? maxDateValue : defaultVal,
      dateFormat: "d-m-Y",
      minDate: startDate,
      maxDate: this.isFinancialClearingValue ? maxDateValue : "today"
    })
  }

  addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  parseDate(dateStr) {
    const parts = dateStr.split('-').map(Number);
    // Determine the date format and construct the date accordingly
    if (parts[0] > 31) {
      // Format is likely YYYY-MM-DD
      return new Date(parts[0], parts[1] - 1, parts[2]);
    } else {
      // Format is likely DD-MM-YYYY
      return new Date(parts[2], parts[1] - 1, parts[0]);
    }
  }
}
